import React from "react";
import { IntlProvider } from "react-intl";

import fr from "../locales/fr.json";
import en from "../locales/en.json";

const messages = { fr, en };

const Wrapper = ({ children, pageContext: { locale } }) => (
  <IntlProvider
    locale={locale ?? "fr"}
    defaultLocale="fr"
    messages={messages[locale]}
  >
    {children}
  </IntlProvider>
);

export default Wrapper;
